<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-candycane</v-icon>
                <h2>¿Cuánto tiempo tarda para bañarse en su casa?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0" justify="center">
               <v-chip-group
                    v-model="ducha"
                    column
                    :error-messages="duchaErrors"
                    class="text-center"
                    @change="setDucha($event)"
                >
                    <v-chip
                    filter
                    outlined
                    value="4"
                    >
                        Menos de 5 minutos
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="9"
                    >
                        Entre 5 y 10 minutos
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="14"
                    >
                        Entre 11 y 15 minutos
                    </v-chip>
                    <v-chip
                    filter
                    outlined
                    value="20"
                    >
                        Más de 15 minutos
                    </v-chip>
               </v-chip-group>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q11"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q13"
                    :disabled="!ducha"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
           <v-col cols="12" sm="8" class="text-justify">
                <v-alert
                    outlined
                    color="info"
                    >
                    <div class="title">
                        Consejos:
                    </div>
                    <div>
                        <strong>Un balde en la ducha:</strong> Ponga un balde en la ducha mientras espera a que salga el agua y utilice el agua que se captura para regar las plantas, o hacer la limpieza del baño.
                        <br>
                        <br>
                        <strong>Instale una regadera de bajo flujo:</strong> Puede costarle algo de dinero, pero sus esfuerzos por conservar el agua le redituaran a corto plazo. Si usa una regadera convencional gastará 18.92 litros de agua por minuto o más, mientras que con ducha de bajo flujo estará gastando 9.46 litros menos. 
                        <br>
                        <br>
                        <strong>Pase menos tiempo en la ducha:</strong> Si pierde la noción del tiempo, ponga la radio en el baño y cuente cuántas canciones pasan mientras está allí. Después trate de reducir su tiempo de ducha a una sola canción.</div>
                </v-alert>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      ducha: { required, numeric },
    },

    data() {
        return {
            ducha: ''
        }
    },

    mounted() {
        this.ducha = this.$store.state.ducha
        this.$store.state.progress = 70
        this.goBackIfEmpty()
    },

    computed: {
       duchaErrors () {
            const errors = []
            if (!this.$v.ducha.$dirty) return errors
            !this.$v.ducha.required && errors.push('Minutos en la ducha es requerido.')
            !this.$v.ducha.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setDucha(value) {
            this.$store.state.ducha = value
            this.$v.ducha.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }
       }
    },
}
</script>

<style>
    .v-slide-group__content{
        justify-content: center;
    }
</style>